import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitch from './LanguageSwitch'; 

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [navbarActive, setNavbarActive] = useState(false);

  const toggleNavbar = () => {
    setNavbarActive(!navbarActive);
  };

  return (
    <header className="header">
      <a href="#home" className="logo">Jesus</a>
      <FontAwesomeIcon icon={faBars} id="menu-icon" onClick={toggleNavbar} />
      <nav className={`navbar ${navbarActive ? 'active' : ''}`}>
        <a href="#home" className="active">{t('home')}</a>
        <a href="#services">{t('services')}</a>
        <a href="#skills">{t('skills')}</a>
        <a href="#repositories">{t('repositories')}</a>
        <a href="#work-experience">{t('experience')}</a>
        <a href="#contact">{t('contact')}</a>
      </nav>
      <LanguageSwitch /> 
    </header>
  );
};

export default Header;
