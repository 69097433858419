import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkExperience: React.FC = () => {
  const { t } = useTranslation();

  const experienceList = [
    {
      title: t("nodeEngineer"),
      company: "ADIGI",
      period: t("periodPresent"),
      location: t("locationRemote", { city: "Asunción" }),
      description: t("adigiDescription"),
      aptitudes: [
        "Node.js", "NestJS", "Express.js", "Fastify", "Docker", "RESTful Web Services", "PostgreSQL", "Amazon ECS", "JIRA", "SQL/NoSQL", "Problem Solving"
      ],
      logoClass: "adigi-logo",
      url: "https://www.adigi.com.py/"
    },
    {
      title: t("backendDeveloper"),
      company: "itti",
      period: t("periodCompleted"),
      location: t("locationRemote", { city: "Asunción" }),
      description: t("ittiDescription"),
      aptitudes: [
        "Node.js", "NestJS", "TypeScript", "Docker", "Mocha", "Vitest", "API Development", "Microservices", "PostgreSQL", "Kubernetes", "AWS", "Terraform", "JIRA", "Apache Kafka", "Swagger"
      ],
      logoClass: "itti-logo",
      url: "https://www.itti.digital/"
    },
    {
      title: t("backendDeveloper"),
      company: "Billetera Mango",
      period: t("periodCompleted"),
      location: t("locationHybrid", { city: "Asunción" }),
      description: t("mangoDescription"),
      aptitudes: [
        "Node.js", "TypeScript", "AWS Lambda", "DynamoDB", "Serverless", "JIRA", "MongoDB", "PostgreSQL"
      ],
      logoClass: "mango-logo",
      url: "https://mangoapp.com.py/"
    },
    {
      title: t("frontendDeveloper"),
      company: "La Plata SRL",
      period: t("periodCompleted"),
      location: t("locationOnSite", { city: "Asunción" }),
      description: t("laplataDescription"),
      aptitudes: [
        "React.js", "Vue.js", "Tailwind CSS", "JavaScript", "Webpack", "GitHub", "SQL Server", "Node.js", "Bootstrap", "C#", "JWT", "SPA", "Linux"
      ],
      logoClass: "laplata-logo",
      url: "[https://www.laplata.com.py/"

    },
    {
      title: t("fullStackDeveloper"),
      company: t("freelance"),
      period: t("periodFreelance"),
      location: t("locationRemote",{ city: "Asunción" }),
      description: t("freelanceDescription"),
      aptitudes: [
        "Node.js", "React Native", "GraphQL", "MySQL", "MongoDB", "Bootstrap", "Git", "Webpack", "PostgreSQL", "UX Design"
      ],
      logoClass: "freelance-logo",
      url: "https://www.freelancer.es/"
    },
    {
      title: t("fullStackDeveloper"),
      company: "ELYON S.A.",
      period: t("periodCompleted"),
      location: t("locationOnSite", { city: "Asunción" }),
      description: t("elyonDescription"),
      aptitudes: [
        "AngularJS", "Ionic", "Firebase", "Node.js", "JWT", "PostgreSQL", "GraphQL", "MongoDB", "HTML5", "CSS", "Babel.js", "C#", "UI Design"
      ],
      logoClass: "elyon-logo"
    },
    {
      title: t("fullStackDeveloper"),
      company: "Carden",
      period: t("periodCompleted"),
      location: t("locationOnSite", { city: "Asunción" }),
      description: t("cardenDescription"),
      aptitudes: [
        "PHP", "Laravel", "NestJS", "Angular", "Ionic", "MySQL", "Node.js", "API Development", "Full Stack Development"
      ],
      logoClass: "carden-logo",
      url:"https://carden.com.py/"
    }
  ];
  return (
    <section className="work-experience" id="work-experience">
      <h2 className="heading">{t('experience')}</h2>
      <div className="timeline">
        {experienceList.map((experience, index) => (
          <div className={`container ${index % 2 === 0 ? "right" : "left"} ${experience.logoClass}`} key={index}>
            <div className="content">
              <div className="tag">
                <h2>{experience.title}</h2>
                <h4>
                  <a href={experience.url} target="_blank" rel="noopener noreferrer">
                    {experience.company}
                  </a>
                </h4>
                <p>{experience.period} · {experience.location}</p>
              </div>
              <div className="decs">
                <p>{experience.description}</p>
                <p><strong>{t("aptitudes")}:</strong></p>
                <ul>
                  {experience.aptitudes.map((aptitude, i) => (
                    <li key={i}>{aptitude}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WorkExperience;
