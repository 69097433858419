import React from 'react';
import { useTranslation } from 'react-i18next';

// Importar las imágenes directamente
import javascriptImg from '../assets/images/skills/javascript.png';
import typescriptImg from '../assets/images/skills/typescript.png';
import phpImg from '../assets/images/skills/php.png';
import pythonImg from '../assets/images/skills/python.png';
import javaImg from '../assets/images/skills/java.png';
import ionicImg from '../assets/images/skills/ionic.png';

import reactImg from '../assets/images/skills/reactjs.png';
import nextjsImg from '../assets/images/skills/next.js.png';
import vueImg from '../assets/images/skills/vuejs.png';
import nuxtjsImg from '../assets/images/skills/nuxtjs.png';
import nodejsImg from '../assets/images/skills/nodejs.png';
import expressjsImg from '../assets/images/skills/expressjs.png';
import nestjsImg from '../assets/images/skills/nestjs.png';
import djangoImg from '../assets/images/skills/django.png';
import springbootImg from '../assets/images/skills/springboot.png';
import laravelImg from '../assets/images/skills/laravel.png';
import angularImg from '../assets/images/skills/angular.png';

import dockerImg from '../assets/images/skills/docker.png';
import awsImg from '../assets/images/skills/aws.png';
import kubernetesImg from '../assets/images/skills/kubernetes.png';
import terraformImg from '../assets/images/skills/terraform.png';

import mochaImg from '../assets/images/skills/mocha.png';
import vitestImg from '../assets/images/skills/vitest.png';
import swaggerImg from '../assets/images/skills/swagger.png';
import postmanImg from '../assets/images/skills/postman.png';

import mysqlImg from '../assets/images/skills/mysql.png';
import mariadbImg from '../assets/images/skills/mariadb.png';
import mongodbImg from '../assets/images/skills/mongodb.png';
import postgresqlImg from '../assets/images/skills/postgresql.png';
import dynamodbImg from '../assets/images/skills/dynamedb.png'

const Skills: React.FC = () => {
  const { t } = useTranslation();

  const skills = [
    {
      category: t('webMobileDevelopment'),
      skills: [
        { name: 'JavaScript', img: javascriptImg },
        { name: 'TypeScript', img: typescriptImg },
        { name: 'PHP', img: phpImg },
        { name: 'Python', img: pythonImg },
        { name: 'Java', img: javaImg },
      ]
    },
    {
      category: t('frameworksLibraries'),
      skills: [
        { name: 'React.js', img: reactImg },
        { name: 'Next.js', img: nextjsImg },
        { name: 'Vue.js', img: vueImg },
        { name: 'Nuxt.js', img: nuxtjsImg },
        { name: 'Node.js', img: nodejsImg },
        { name: 'Express.js', img: expressjsImg },
        { name: 'NestJS', img: nestjsImg },
        { name: 'Django', img: djangoImg },
        { name: 'Spring Boot', img: springbootImg },
        { name: 'Laravel', img: laravelImg },
        { name: 'Ionic', img: ionicImg },
        { name: 'Angular', img: angularImg }
      ]
    },
    {
      category: t('devOpsCloud'),
      skills: [
        { name: 'Docker', img: dockerImg },
        { name: 'AWS', img: awsImg },
        { name: 'Kubernetes', img: kubernetesImg },
        { name: 'Terraform', img: terraformImg }
      ]
    },
    {
      category: t('testingQuality'),
      skills: [
        { name: 'Mocha', img: mochaImg },
        { name: 'Vitest', img: vitestImg },
        { name: 'Swagger', img: swaggerImg },
        { name: 'Postman', img: postmanImg }
      ]
    },
    {
      category: t('databasesBackend'),
      skills: [
        { name: 'MySQL', img: mysqlImg },
        { name: 'MariaDB', img: mariadbImg },
        { name: 'MongoDB', img: mongodbImg },
        { name: 'PostgreSQL', img: postgresqlImg },
        { name: 'DynamoDB', img: dynamodbImg }
      ]
    }
  ];

  return (
    <section className="skills" id="skills">
      <h2 className="heading">{t('skills')}</h2>
      <div className="container">
        {skills.map((category, index) => (
          <div key={index} className="skills-category">
            <h3>{category.category}</h3>
            <div className="row" id="skillsContainer">
              {category.skills.map((skill, skillIndex) => (
                <div className="bar" key={skillIndex}>
                  <div className="info">
                    {/* Agregamos la clase 'small-image' a las imágenes */}
                    <img className="small-image" src={skill.img} alt={skill.name} />
                    <span>{skill.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
