import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      home: "Home",
      services: "Services",
      skills: "Skills",
      experience: "Experience",
      contact: "Contact",
      repositories:"Reposotories",
      hireMe: "Hire Me",
      contact2: "Contact",
      sendMessage: "Send Message",
      me: "Me",
      name: "Name",
      email: "Email",
      phoneNumber: "Phone Number",
      subject: "Subject",
      yourMessage: "Your Message",
      profileIntro: "Hi, It's",
      typingText: "I'm a",
      description: "I'm Jesus, a full-stack developer with 5+ years of experience. I love challenges and building innovative solutions with modern technologies, always striving to improve and deliver impactful applications.",
      fullStackWebDevelopment: "Full-Stack Web Development",
      mobileAppDevelopment: "Mobile App Development",
      uiUxDesign: "UI / UX Design",
      devOpsAndCloudSolutions: "DevOps & Cloud Solutions",
      apiIntegrationAndDevelopment: "API Integration & Development",
      softwareTestingAndQA: "Software Testing & QA",
      softwareDevelopment: "Software Development",
      providingExpertService: "Providing expert {{service}} solutions tailored to your needs.",
      webMobileDevelopment: "Web & Mobile Development",
      frameworksLibraries: "Frameworks & Libraries",
      devOpsCloud: "DevOps & Cloud",
      testingQuality: "Testing & Quality",
      databasesBackend: "Databases & Backend",
      
      // Work Experience Section
      nodeEngineer: "Node Engineer",
      backendDeveloper: "Backend Developer",
      frontendDeveloper: "Frontend Developer",
      webDeveloper: "Web Developer",
      fullStackDeveloper: "Full Stack Developer",
      freelance: "Freelance",
      periodPresent: "May 2024 - Present",
      periodCompleted: "Completed",
      periodFreelance: "Dec 2019 - Dec 2023",
      locationRemote: "{{city}}, Paraguay · Remote",
      locationHybrid: "{{city}}, Paraguay · Hybrid",
      locationOnSite: "{{city}}, Paraguay · On-site",
      adigiDescription: "Responsible for developing and maintaining scalable backend services using Node.js, Express.js, and NestJS",
      ittiDescription: "Developed microservices and APIs using Node.js, NestJS, and TypeScript",
      mangoDescription: "Worked on serverless architecture with AWS Lambda and DynamoDB",
      laplataDescription: "Developed responsive web applications using React.js and Vue.js",
      freelanceDescription: "Built various web and mobile applications for clients using Node.js and React Native",
      elyonDescription: "Developed front-end interfaces using AngularJS and Ionic",
      cardenDescription: "Led full-stack development using Laravel for the backend and Angular for the frontend",
      aptitudes: "Aptitudes"
    }
  },
  es: {
    translation: {
      home: "Inicio",
      services: "Servicios",
      skills: "Habilidades",
      experience: "Experiencia",
      contact: "Contacto",
      repositories:"Repositorios",
      hireMe: "Contrátame",
      sendMessage: "Enviar Mensaje",
      contact2:"Contacta",
      me: "Me",
      name: "Nombre",
      email: "Correo Electrónico",
      phoneNumber: "Número de Teléfono",
      subject: "Asunto",
      yourMessage: "Tu Mensaje",
      profileIntro: "Hola, soy",
      typingText: "Soy un",
      description: "Soy Jesús, un desarrollador full-stack con más de 5 años de experiencia. Me encantan los desafíos y construir soluciones innovadoras con tecnologías modernas, siempre esforzándome por mejorar y entregar aplicaciones impactantes.",
      fullStackWebDevelopment: "Desarrollo Web Full-Stack",
      mobileAppDevelopment: "Desarrollo de Aplicaciones Móviles",
      uiUxDesign: "Diseño UI / UX",
      devOpsAndCloudSolutions: "Soluciones DevOps y en la Nube",
      apiIntegrationAndDevelopment: "Integración y Desarrollo de API",
      softwareTestingAndQA: "Pruebas de Software y Control de Calidad",
      softwareDevelopment: "Desarrollo de Software",
      providingExpertService: "Ofreciendo soluciones expertas de {{service}} adaptadas a sus necesidades.",
      webMobileDevelopment: "Desarrollo Web y Móvil",
      frameworksLibraries: "Frameworks y Librerías",
      devOpsCloud: "DevOps y Nube",
      testingQuality: "Pruebas y Calidad",
      databasesBackend: "Bases de Datos y Backend",
      
      // Work Experience Section
      nodeEngineer: "Ingeniero de Node",
      backendDeveloper: "Desarrollador Backend",
      frontendDeveloper: "Desarrollador Frontend",
      webDeveloper: "Desarrollador Web",
      fullStackDeveloper: "Desarrollador Full Stack",
      freelance: "Freelance",
      periodPresent: "May 2024 - Actualidad",
      periodCompleted: "Completado",
      periodFreelance: "Dic 2019 - Dic 2023",
      locationRemote: "{{city}}, Paraguay · Remoto",
      locationHybrid: "{{city}}, Paraguay · Híbrido",
      locationOnSite: "{{city}}, Paraguay · Presencial",
      adigiDescription: "Responsable de desarrollar y mantener servicios backend escalables usando Node.js, Express.js y NestJS",
      ittiDescription: "Desarrollo de microservicios y APIs usando Node.js, NestJS y TypeScript",
      mangoDescription: "Trabajó en arquitectura serverless con AWS Lambda y DynamoDB",
      laplataDescription: "Desarrollo de aplicaciones web responsivas utilizando React.js y Vue.js",
      freelanceDescription: "Construyó varias aplicaciones web y móviles para clientes utilizando Node.js y React Native",
      elyonDescription: "Desarrollo de interfaces front-end usando AngularJS e Ionic",
      cardenDescription: "Lideró el desarrollo full-stack utilizando Laravel para el backend y Angular para el frontend",
      aptitudes: "Aptitudes"
    }
  }
};



i18n.use(initReactI18next).init({
  resources,
  lng: "en", 
  fallbackLng: "en", 
  interpolation: {
    escapeValue: false
  }
});

export default i18n;

export {};
