import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LanguageSwitch: React.FC = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      i18n.changeLanguage('es'); 
    } else {
      i18n.changeLanguage('en'); 
    }
  };

  return (
    <StyledWrapper>
      <div className="btn-container">
        <label className="switch btn-color-mode-switch">
          <input
            value="1"
            id="language_mode"
            name="language_mode"
            type="checkbox"
            onChange={handleLanguageChange}
          />
          <label
            className="btn-color-mode-switch-inner"
            data-off="EN"
            data-on="ES"
            htmlFor="language_mode"
          />
        </label>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .btn-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative;
  }

  .btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 140px;
    height: 40px;
    background-color: #e1362c; /* Cambiado a rojo */
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
  }

  .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top: 7px;
    right: 20px;
    color: #fff; /* Cambiado a blanco */
  }

  .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 70px;
    height: 25px;
    background: #fff;
    border-radius: 26px;
    position: absolute;
    font-size: 20px;
    display: flex;
    justify-content: center;
    left: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
    color: #e1362c; /* Cambiado a rojo */
    font-weight: 600;
  }

  .btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
  }

  .btn-color-mode-switch input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner {
    background-color: #e1362c; /* Cambiado a rojo */
  }

  .btn-color-mode-switch input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 68px;
  }

  .btn-color-mode-switch input[type="checkbox"]:checked
    + label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    left: 20px;
  }
`;

export default LanguageSwitch;
