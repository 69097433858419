import React from 'react';
import { useTranslation } from 'react-i18next';

const Services: React.FC = () => {
  const { t } = useTranslation();
  
  const services = [
    t('fullStackWebDevelopment'),
    t('mobileAppDevelopment'),
    t('uiUxDesign'),
    t('devOpsAndCloudSolutions'),
    t('apiIntegrationAndDevelopment'),
    t('softwareTestingAndQA'),
    t('softwareDevelopment')
  ];

  return (
    <section className="services" id="services">
      <h2 className="heading">{t('services')}</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-box" key={index}>
            <div className="service-info">
              <h4>{service}</h4>
              <p>{t('providingExpertService', { service: service.toLowerCase() })}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
