import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card'; 
const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="home" id="home">
      <div className="home-content">
        <h1>{t('profileIntro')} <span>Jesus</span></h1>
        <h3 className="typing-text">{t('typingText')} <span></span></h3>
        <p style={{ marginBottom: "10px" }}>
          {t('description')}
        </p>
        <a href="#contact" className="btn">{t('hireMe')}</a>
      <Card cmdText="create-react-app@latest portfolio" />
      </div>
    </section>
  );
};

export default Home;
