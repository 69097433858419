import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import githubLogo from '../assets/images/logos/github-logo.png'; 

const Repositories: React.FC = () => {
  const { t } = useTranslation();
  const [repos, setRepos] = useState<any[]>([]);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await fetch('https://api.github.com/users/iamLayzo/repos');
        const data = await response.json();

        const selectedRepos = data.filter((repo: any) =>
          repo.name === 'limitGuardian' || 
          repo.name === 'my-fantasy-api' || 
          repo.name === 'RedNode' || 
          repo.name === 'oscillator-visualizer' ||
          repo.name === 'textExtractor'
        );
        setRepos(selectedRepos);
      } catch (error) {
        console.error('Error fetching repositories', error);
      }
    };

    fetchRepos();
  }, []);

  return (
    <section className="repositories" id="repositories">
      <div className="repositories-header">
        <img
          src={githubLogo}
          alt="GitHub Logo"
          className="github-logo"
        />
        <h2 className="heading">{t('repositories')}</h2>
      </div>
      <div className="repositories-container">
        {repos.map((repo, index) => (
          <div className="repository-box" key={index}>
            <div className="repository-icon">📁</div> 
            <div className="repository-info">
              <h4>
                <a 
                  href={repo.html_url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="repo-link"
                >
                  {repo.name}
                </a>
              </h4>
              <p>{repo.description}</p>
              <div className="repository-stats">
                <span className="stat">⭐ {repo.stargazers_count}</span>
                <span className="stat">🔱 {repo.forks_count}</span>
                <span className="language">
                  <span className={`lang-dot ${repo.language?.toLowerCase()}`}></span>
                  {repo.language}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Repositories;
