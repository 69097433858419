import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com'; 
const Contact: React.FC = () => {
  const { t } = useTranslation();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: ''
  });
  const [sent, setSent] = useState(false); 
  const [error, setError] = useState(false); 

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const serviceID = 'service_06cly66'; 
    const templateID = 'template_90zrdqg';
    const userID = '5gs5slbWmyXRTQVnO'; 

    try {
      await emailjs.send(serviceID, templateID, formData, userID);
      setSent(true); 
      setError(false);
      setFormData({ name: '', email: '', phoneNumber: '', subject: '', message: '' }); 
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      setError(true); // Muestra mensaje de error
    }
  };

  return (
    <section className="contact" id="contact">
      <h2 className="heading">{t('contact2')} <span>{t('me')}</span></h2> 
      <form onSubmit={handleSubmit}>
        <div className="input-box">
          <input
            type="text"
            name="name"
            placeholder={t('name')}
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder={t('email')}
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="phoneNumber"
            placeholder={t('phoneNumber')}
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder={t('subject')}
            value={formData.subject}
            onChange={handleChange}
          />
        </div>
        <textarea
          name="message"
          cols={30}
          rows={10}
          placeholder={t('yourMessage')}
          value={formData.message}
          onChange={handleChange}
        ></textarea>
        <input type="submit" value={t('sendMessage')} className="btn" />
      </form>
      {sent && <p>{t('messageSent')}</p>}
      {error && <p>{t('messageError')}</p>}
    </section>
  );
};

export default Contact;
